<template>
  <div>
    
    <b-card
    style="border:none; margin-right:10%;margin-left:10%;"
    text-variant="dark"
    class="mt-5 pt-1"
  >
    <div>
       <marquee style="color:red; background:#fff;font-weight:bold;font-size:20px"  behavior="" direction="left"> {{ $t('sitetest') }}  </marquee>
      <b-card no-body>
        <b-tabs
          active-nav-item-class="font-weight-bold text-uppercase text-danger"
          fill
          card
        >
          <b-tab active>
            <b-card-text>
              <b-row>
                <b-col sm="3">
                  <div>
                    <custom-input
                      :label="$t('docNumver')"
                      type="text"
                      v-model="filter.docnumber"
                      @enter="Refresh"
                    ></custom-input>
                  </div>
                </b-col>
                <b-col sm="3">
                  <div>
                    <custom-date
                      :label="$t('docdate')"
                      v-model="filter.docdate"
                      @keyup="BindValue"
                    >
                    </custom-date>
                  </div>
                </b-col>
                <b-col sm="3">
                  <div>
                    <custom-input
                      :label="$t('docname')"
                      type="text"
                      v-model="filter.docname"
                      v-on:keyup.enter="Search"
                    ></custom-input>
                  </div>
                </b-col>
                <b-col sm="3">
                  <div>
                    <custom-select
                      :label="$t('typedocument')"
                      v-model="filter.documentypeid"
                      :options="documenttype"
                    >
                    </custom-select>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col sm="3">
                  <div>
                    <custom-select
                      :label="$t('nomerregister')"
                      v-model="filter.registeredorgid"
                      :options="registeredorg"
                    >
                    </custom-select>
                  </div>
                </b-col>
                <b-col sm="3">
                  <div>
                    <custom-select
                      :label="$t('nomerlanguage')"
                      v-model="filter.languageid"
                      :options="languagelist"
                    >
                    </custom-select>
                  </div>
                </b-col>
                <b-col sm="3">
                  <div>
                    <custom-select
                      :options="characterlist"
                      :label="$t('namecharacter')"
                      v-model="filter.characteractid"
                    >
                    </custom-select>
                  </div>
                </b-col>
                <b-col sm="3">
                  <div>
                    <custom-input
                      :label="$t('tagname')"
                      type="text"
                      v-model="filter.tagname"
                      v-on:keyup.enter="Search"
                    ></custom-input>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <div class="text-right">
                    <b-button
                      @click="Search"
                      style="margin-right:10px"
                      class="btn2"
                      ><b-icon icon="search" style="margin-right:10px"></b-icon>
                      {{ $t("search") }}</b-button
                    >
                    <b-button class="btn1" @click="clear"
                      ><b-icon
                        icon="x-circle"
                        style="margin-right:10px"
                      ></b-icon
                      >{{ $t("clear") }}</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-card>
        <b-table
          :fields="fields"
          :items="items"
          responsive="sm"
          style="min-height:800px"
          show-empty
          :busy="isBusy"
          :current-page="filter.PageNumber"
          striped
          sticky-header="60vh"
          no-border-collapse
          bordered
        >
          <!-- thead-class="hidden_header" -->
          <template #cell(watch)="{item}">
            <!-- <b-link
              v-if="isshow"
              @click="OpenModal(item)"
              v-b-tooltip.hover.top="$t('Show')"
            >
              <b-icon icon="eye"></b-icon>
            </b-link> -->

            <b-link @click="OpenModal(item)" v-b-tooltip.hover.top="$t('Show')">
              <b-icon icon="eye"></b-icon>
            </b-link>
          </template>
          <template v-slot:empty>
            <h5 class="text-center">{{ $t("NoItems") }}</h5>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="text-center" style="font-size:25px">
              <b-icon
                icon="check-circle-fill"
                variant="success"
                v-if="item.documentstatusname === 'Амалдаги'"
              ></b-icon>
              <b-icon
                icon="x-circle-fill"
                variant="danger"
                v-if="item.documentstatusname === 'Ўз кучини йўқотган'"
              ></b-icon>
            </div>
          </template>
          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails"
              >{{ row.detailsShowing ? "Hide" : "Show" }}Info</b-button
            >
          </template>
          <template #cell(urladdress)="{item}">
            <a
              v-if="item.urladdress != 'null'"
              :href="item.urladdress"
              target="_blank"
              >{{ item.urladdress }}</a
            >
            <p v-if="item.urladdress == 'null'">{{ $t("") }}</p>
          </template>
        </b-table>
        <div class="ml-3 mr-3">
          <b-row>
            <b-col>
              <div class="pb-4 mb-4 mt-3">
                <div style="float:left;font-size:12px">
                  {{ $t("RecordsFrom") }} {{ this.firstNumber }} {{ $t("by") }}
                  {{ this.lastNumber }} {{ $t("of") }}
                  {{ this.totalRows }}
                </div>
                <div style="float:left; width:7%;" class="ml-4 mr-2 mt-n1">
                  <b-form-select
                    v-model="filter.PageLimit"
                    id="perPageSelect"
                    size="sm"
                    :options="filter.pageOptions"
                    @input="Refresh"
                  ></b-form-select>
                </div>
                <div style="float:left;font-size:12px">
                  {{ $t("RecordsPerPage") }}
                </div>
                <div style="float:right">
                  <b-pagination
                    v-model="filter.PageNumber"
                    :total-rows="totalRows"
                    @input="Refresh"
                    :per-page="filter.PageLimit"
                    align="right"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal
        class="ModalStyle"
        size="xl"
        no-close-on-backdrop
        hide-footer
        cancel-none
        :cancel-title="$t('Cancel')"
        cancel-variant="danger"
        v-model="ModalShow"
      >
        <template #modal-title>
          <b-row v-if="GetInfo.documentstatusname == 'Амалдаги'">
            <b-col>
              <p style="color:#29C76F">{{ GetInfo.documentstatusname }}</p>
            </b-col>
          </b-row>
          <b-row v-if="GetInfo.documentstatusname == 'Ўз кучини йўқотган'">
            <b-col>
              <p style="color:red;font-size:18px">
                {{ GetInfo.documentstatusname }}
              </p>
            </b-col>
          </b-row>
          <b-row class="textPlace">
            <b-col class="textPlace">
              <p style="font-size:20px;text-align:center !important">
                <span class="textView"> {{ GetInfo.registeredorgname }}</span>
                ( {{ GetInfo.documentypename }})
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="textPlace">
              <p
                style="font-size:20px;font-weight:bold;text-align:center !important"
              >
                {{ GetInfo.docname }}
              </p>
            </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col class="text-center">
            <p style="font-size:20px;font-weight:bold">
              № {{ GetInfo.docnumber }} <span> </span> - {{ GetInfo.docdate }}
            </p>
          </b-col>
        </b-row>

        <b-table-simple sticky-header="500px">
          <b-row class="ml-4 pl-4 mr-4 pr-4 ">
            <b-col
              sm="12"
              lg="12"
              v-for="(item3, index3) in Paragraph"
              :key="index3"
            >
              <div class="mb-4 pb-4 " v-html="item3.paragraphtext"></div>
            </b-col>
          </b-row>
        </b-table-simple>
        <b-row>
          <b-col v-for="(item2, index) in LoadFile" :key="index">
            <iframe
              v-if="Paragraph.length == 0"
              style="width:100%;min-height:500px; "
              :src="
                axios.defaults.baseURL +
                  `Document/GetAttachedFile?id=${GetInfo.id}&fileid=${item2.id}`
              "
              frameborder="0"
            ></iframe>
          </b-col>
        </b-row>

        <b-row
          style="background-color:#FFFAF9;border-radius:25px;margin-top:40px !important"
          class="m-4 "
        >
          <b-col sm="12" lg="1" v-for="(item2, index) in LoadFile" :key="index">
            <!-- <b-row v-if="Paragraph.length == 0">
              <b-col>
                <iframe
                  :src="
                    axios.defaults.baseURL +
                      `Document/GetAttachedFile?id=${GetInfo.id}&fileid=${item2.id}`
                  "
                  frameborder="0"
                ></iframe>
              </b-col>
            </b-row> -->

            <b-card style="width:80px" class="downloadCard">
              <b-row>
                <b-col sm="12" class="text-right mr-0 pr-0" lg="9">
                  <a
                    v-b-tooltip.hover.top="$t('Show')"
                    class="m-0 p-0"
                    :href="
                      axios.defaults.baseURL +
                        `Document/GetAttachedFile?id=${GetInfo.id}&fileid=${item2.id}`
                    "
                    target="_blank"
                  >
                    <img width="100%" src="@/assets/pdf.png" alt="" />
                  </a>
                </b-col>
                <b-col sm="12" class="text-left ml-0 pl-0" lg="3">
                  <div style="margin-top:-10px">
                    <b-link
                      v-b-tooltip.hover.top="$t('download')"
                      style="cursor:pointer"
                      @click="DownLoad(item2)"
                      name="cil-cloud-download"
                    >
                      <b-icon icon="cloud-download-fill"> </b-icon>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button class="m-2" variant="danger" @click="CloseModal"
              >{{ $t("back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </b-card>
  </div>
</template>

<script>
import DocumentService from "@/services/Document.service";
import HelperService from "@/services/Helper.service";
import customSelect from "@/components/customSelect.vue";
import customDate from "@/components/customDate.vue";
import customInput from "@/components/customInput.vue";
import axios from "axios";

export default {
  components: {
    customSelect,
    customDate,
    customInput,
  },
  data() {
    return {
      axios,
      ModalShow: false,
      isshow: false,
      fields: [
        {
          key: "actions",
          label: this.$t("hujjat_holati"),
          thClass: "text-center",
        },

        {
          key: "docnumber",
          label: this.$t("hujjat_raqami"),

          thClass: "text-center",
          tdClass: "text-right",
        },
        {
          key: "docdate",
          label: this.$t("Sana"),

          thClass: "text-center",
          tdClass: "text-right",
        },
        {
          key: "docname",
          label: this.$t("hujjat_nomi"),

          thClass: "text-center",
          tdClass: "text-left",
        },
        {
          key: "registeredorgname",
          label: this.$t("qaror"),

          thClass: "text-center",
          tdClass: "text-left",
        },
        {
          key: "documentypename",
          label: this.$t("hujjat_turi"),

          thClass: "text-center",
          tdClass: "text-left",
        },
        {
          key: "urladdress",
          label: this.$t("hujjat_manzili"),

          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "characteractname",
          label: this.$t("hujjat_tusi"),

          thClass: "text-center",
          tdClass: "text-left",
        },

        {
          key: "watch",
          label: this.$t("watch"),

          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      items: [],
      documenttype: [],
      registeredorg: [],
      languagelist: [],
      characterlist: [],
      totalRows: "",
      isBusy: true,
      filter: {
        Search: "",
        SortColumn: "",
        OrderType: "asc",
        PageNumber: 1,
        PageLimit: 20,
        pageOptions: [5, 10, 15, 20, 25, 50, 100],
        SortIcon: "cil-arrow-top",
        docnumber: "",
        docdate: "",
        docname: "",
        documentypeid: 0,
        registeredorgid: 0,
        languageid: 0,
        characteractid: 0,
        tagname: "",
      },
      Document: {},
      GetInfo: {},
      LoadFile: [],
      Paragraph: [],
      DownloadLoading: false,
    };
  },
  created() {
    HelperService.GetAllDocumenType().then((res) => {
      this.documenttype = res.data;
    });
    HelperService.GetAllRegisteredOrg().then((res) => {
      this.registeredorg = res.data;
    });
    HelperService.GetAllLanguage().then((res) => {
      this.languagelist = res.data;
    });
    HelperService.GetAllCharacterAct().then((res) => {
      this.characterlist = res.data;
    });
    this.Refresh();
  },
  computed: {
    firstNumber() {
      return this.totalRows > 0
        ? (this.filter.PageNumber - 1) * this.filter.PageLimit + 1
        : 0;
    },
    lastNumber() {
      if (this.totalRows < this.filter.PageLimit) {
        return this.totalRows;
      } else {
        if (this.filter.PageNumber * this.filter.PageLimit > this.totalRows) {
          return this.totalRows;
        } else {
          return this.filter.PageNumber * this.filter.PageLimit;
        }
      }
    },
  },
  methods: {
    OpenModal(item) {
      var self = this;
      self.ModalShow = true;

      DocumentService.Get(item.id).then((res) => {
        self.GetInfo = res.data;
        self.LoadFile = res.data.TablesFile;
        self.Paragraph = res.data.TablesParagraph;
        // if (self.Paragraph.length == 0) {
        // console.log(self.$refs.openPDF);
        // console.log(self.$href);

        // self.$refs.openPDF;
        // console.log(this.ref);
        // console.log(this.$refs.input);
        // console.log(self.$refs.openPdf);
        // console.log(self.refs);
        // }
      });
    },
    CloseModal() {
      this.ModalShow = false;
    },
    ShowModal(item) {
      var self = this;
      DocumentService.Get(item.id).then((res) => {
        self.GetInfo = res.data;
        self.LoadFile = res.data.TablesFile;
      });
    },
    forceFileDownload(response, name, attachfilename) {
      var headers = response.headers;
      var blob = new Blob([response.data]);

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      let format = attachfilename.split(".");
      if (format.length > 0) {
        link.setAttribute(
          "download",
          format[0] + "." + format[format.length - 1]
        );
      }
      // link.setAttribute("download", name + "");
      document.body.appendChild(link);
      link.click();
    },
    DownLoad(item) {
      this.DownloadLoading = true;
      DocumentService.GetAttachedFile(this.GetInfo.id, item.id)
        .then((res) => {
          this.makeToast(
            this.$t("SuccessDownload"),
            this.$t("message"),
            "success"
          );
          this.forceFileDownload(res, item.id, item.attachfilename);

          this.DownloadLoading = false;
        })
        .catch((err) => {
          this.makeToast(err.response.data.error, this.$t("error"), "danger");
          this.DownloadLoading = false;
        });
    },
    Close(item) {
      this.$bvModal.hide(item.id);
    },
    BindValue(value) {
      this.filter.docdate = value;
    },
    Search() {
      // if (this.filter.PageNumber !== 1) {
      //   this.filter.PageNumber = 1;
      // } else {
      //   this.Refresh();
      // }
      this.Refresh();
    },
    clear() {
      (this.filter.docnumber = ""),
        (this.filter.docdate = ""),
        (this.filter.docname = ""),
        (this.filter.documentypeid = 0),
        (this.filter.registeredorgid = 0),
        (this.filter.languageid = 0),
        (this.filter.characteractid = 0),
        (this.filter.tagname = "");
      this.items = [];
    },
    makeToast(message, title, type) {
      this.toastCount++;
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 2000,
        variant: type,
        solid: true,
      });
    },
    Refresh() {
      this.isBusy = true;
      DocumentService.GetList(
        this.filter.Search,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.docnumber,
        this.filter.docdate,
        this.filter.docname,
        this.filter.documentypeid,
        this.filter.registeredorgid,
        this.filter.languageid,
        this.filter.characteractid,
        this.filter.tagname
      ).then((res) => {
        this.isBusy = false;
        this.items = res.data.rows;
        this.totalRows = res.data.total;
      });
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
.modal-header {
  width: 100%;
  background-color: white;
}
.downloadCard {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}
.modal-content {
  border-radius: 10px;
  border-top: 5px solid rgb(27, 179, 103) !important;
}
.textView {
  text-transform: uppercase;
}
.textPlace {
  text-align: center !important;
}
.textViewSticky {
  font-size: 15px;
  color: #000000;
  border: 1px solid black;
  position: fixed;
  height: 100px;
  width: 100%;
  transition: all 0.4s ease;
}
.header {
  font-size: 30px;
  color: #ffffff;
  border: 1px dashed orange;
  position: fixed;
  height: 150px;
  width: 100%;
  text-align: center;
}
</style>
