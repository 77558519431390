<template>
  <div class="my-custom-select">
    <label> {{ label }} </label>
    <v-select
      :options="options"
      :reduce="(item) => item[valueid]"
      :label="valuename"
      :placeholder="$t('choose')"
      :clearable="false"
      :disabled="disabled"
      v-model="updateVal"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <img src="/images/design/arrow-down.svg" alt="" />
        </span>
      </template>
      <span slot="no-options"> {{ $t("nothinghere") }} </span>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  components: { vSelect },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valuename: {
      type: String,
      default: "name",
    },
    valueid: {
      type: String,
      default: "id",
    },
    value: {},
  },
  computed: {
    updateVal: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scope>
@import "~vue-select/src/scss/vue-select";
@import "~vue-select/src/scss/global/_variables.scss";
.my-custom-select {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 12px;
  label {
    width: 100%;
    padding-left: 16px;
    text-transform: uppercase;
    font-size: 12px;
  }
  .v-select.vs--single.vs--searchable {
    .vs__dropdown-toggle {
      border: none;
      .vs__selected-options {
        input {
          padding-left: 12px;
          margin-bottom: 6px;
          margin-top: 0;
          &::placeholder {
            font-size: 15px;
            color: #a7bbbd;
          }
        }
        .vs__selected {
          padding-left: 12px;
          margin-top: 0;
          margin-bottom: 6px;
        }
      }
      .vs__actions {
        .vs__open-indicator {
          margin-top: -29px;
          margin-right: 15px;
        }
      }
    }
    .vs__dropdown-menu {
      margin-top: 16px;
      border-radius: 12px;
    }
  }
}
</style>
