import axios from 'axios'

const ApiService = {
    removeHeader(){
        axios.defaults.headers.common = {}
    },
    get(resource){
        return axios.get(resource)
    },
    post(resource,data){
        return axios.post(resource,data)
    },
    formData(resource,data){
        return axios.post(resource,data,{
            headers:{
                'Content-type':'multipart/form-data'
            }
        })
    },
    put(resource,data){
        return axios.put(resource,data)
    },
    delete(resource){
        return axios.delete(resource)
    },
    print(resource){
        return axios.get(resource,{
            responseType:'blob'
        })
    },
    printtemp(resource,data){
        return axios.post(resource,data,{
            responseType : 'blob'
        })
    }
}
export default ApiService