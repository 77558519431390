import ApiService from './api.service'

const HelperService = {
    GetAllCharacterAct(){
        return ApiService.get('/Helper/GetAllCharacterAct/')
    },
    GetAllDocumentStatus(){
        return ApiService.get('/Helper/GetAllDocumentStatus/')
     },
     GetAllDocumenType(){
       return ApiService.get('/Helper/GetAllDocumenType')
      },
     GetAllLanguage(){
      return ApiService.get('/Helper/GetAllLanguage/')
      },
     GetAllState(){
      return ApiService.get('/Helper/GetAllState/')
     },
     GetAllRegisteredOrg(){
      return ApiService.get('/Helper/GetAllRegisteredOrg/')
     },
     GetAllTags(){
        return ApiService.get(`/Helper/GetAllTags`)
    }
}
export default HelperService