import ApiService from "./api.service";

const DocumentService = {
  GetList(
    Search,
    SortColumn,
    OrderType,
    PageNumber,
    PageLimit,
    docnumber,
    docdate,
    docname,
    documentypeid,
    registeredorgid,
    languageid,
    characteractid,
    tagname
  ) {
    return ApiService.get(
      `/Document/GetList?Search=${Search}&SortColumn=${SortColumn}&Ordertype=${OrderType}&PageNumber=${PageNumber}&PageLimit=${PageLimit}&docnumber=${docnumber}&docdate=${docdate}&docname=${docname}&documentypeid=${documentypeid}&registeredorgid=${registeredorgid}&languageid=${languageid}&characteractid=${characteractid}&tagname=${tagname}`
    );
  },
  Get(id) {
    return ApiService.get(`/Document/Get?id=${id}`);
  },
  GetAttachedFile(id, fileid) {
    return ApiService.get(
      `/Document/GetAttachedFile?id=${id}&fileid=${fileid}`
    );
  },
};
export default DocumentService;
